var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PaymentCardBase',{attrs:{"title":_vm.title,"active":_vm.active},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('section',{staticClass:"p-5 border-t border-b",staticStyle:{"width":"100%"}},[_c('splide',{attrs:{"options":_vm.splideOption},on:{"splide:move":_vm.onMove}},_vm._l((_vm.cards),function(card,cardIndex){return _c('splide-slide',{key:cardIndex},[_c('credit-card',{attrs:{"name":_vm.fullName,"number":card.number,"brand":card.brand,"expiry":card.expiry}})],1)}),1)],1),_c('section',[_c('feature-list-typography',{attrs:{"text-left":"Total Amount Spent","text-right":(_vm.currencySymbol + " " + (Number(
            _vm.getCurrentCard.payment_summary.total_amount || 0
          ).toFixed(2)))}}),_c('feature-list-typography',{attrs:{"text-left":"Total Trip Payment","text-right":(_vm.currencySymbol + " " + (Number(
            _vm.getCurrentCard.payment_summary.total_trip_payment
          ).toFixed(2)))}}),_c('feature-list-typography',{attrs:{"text-left":"Total Pass Payment","text-right":(_vm.currencySymbol + " " + (Number(
            _vm.getCurrentCard.payment_summary.total_pass_payment
          ).toFixed(2)))}}),_c('feature-list-typography',{attrs:{"text-left":"Total Topup Payment","text-right":(_vm.currencySymbol + " " + (Number(
            _vm.getCurrentCard.payment_summary.total_topup_payment
          ).toFixed(2)))}}),_c('feature-list-typography',{attrs:{"text-left":"Total Refunded","text-right":(_vm.currencySymbol + " " + (Number(
            _vm.getCurrentCard.payment_summary.total_refunded
          ).toFixed(2)))}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }