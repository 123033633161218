<template>
  <PaymentCardBase :title="title" :active="active">
    <template v-slot:body>
      <section class="p-5 border-t border-b" style="width: 100%;">
        <splide :options="splideOption" @splide:move="onMove">
          <splide-slide v-for="(card, cardIndex) in cards" :key="cardIndex">
            <credit-card
              :name="fullName"
              :number="card.number"
              :brand="card.brand"
              :expiry="card.expiry"
            />
          </splide-slide>
        </splide>
      </section>

      <section>
        <feature-list-typography
          :text-left="`Total Amount Spent`"
          :text-right="
            `${currencySymbol} ${Number(
              getCurrentCard.payment_summary.total_amount || 0
            ).toFixed(2)}`
          "
        />

        <feature-list-typography
          :text-left="`Total Trip Payment`"
          :text-right="
            `${currencySymbol} ${Number(
              getCurrentCard.payment_summary.total_trip_payment
            ).toFixed(2)}`
          "
        />

        <feature-list-typography
          :text-left="`Total Pass Payment`"
          :text-right="
            `${currencySymbol} ${Number(
              getCurrentCard.payment_summary.total_pass_payment
            ).toFixed(2)}`
          "
        />

        <feature-list-typography
          :text-left="`Total Topup Payment`"
          :text-right="
            `${currencySymbol} ${Number(
              getCurrentCard.payment_summary.total_topup_payment
            ).toFixed(2)}`
          "
        />

        <feature-list-typography
          :text-left="`Total Refunded`"
          :text-right="
            `${currencySymbol} ${Number(
              getCurrentCard.payment_summary.total_refunded
            ).toFixed(2)}`
          "
        />
      </section>
    </template>
  </PaymentCardBase>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export default {
  name: 'PaymentCardCreditBlock',
  title: 'Cards',
  components: {
    Splide,
    SplideSlide,
    CreditCard: () => import('@/components/cards/CreditCard.vue'),
    PaymentCardBase: () => import('./PaymentCardBaseBlock'),
    FeatureListTypography: () =>
      import('@/components/typography/FeatureListTypography.vue'),
  },
  props: {
    title: {
      type: [String, Number],
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    currencySymbol: {
      type: String,
      required: false,
      default: '$',
    },
    totalDeposited: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    totalSpent: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    totalTrips: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    fullName: {
      type: String,
      required: false,
    },
    cards: {
      type: Array,
      requied: false,
    },
  },

  data() {
    return {
      splideOption: {
        rewind: true,
        width: '100%',
        fixedWidth: '100%',
        perPage: 1,
        // gap: "1rem"
        /*  padding: {
          right: "0.25rem",
          left: "0rem"
        } */
      },
      currentSlide: 0,
    }
  },

  computed: {
    getCurrentCard() {
      return this.cards[this.currentSlide]
    },
  },

  methods: {
    onMove(_e, newIndex) {
      // console.log('onMove', newIndex)
      this.currentSlide = newIndex
    },
  },
}
</script>

<style lang="scss" scoped></style>
